import axios, { AxiosError, AxiosResponse } from "axios";

import { toast } from "react-toastify";

import { PermissionDto, Role, RoleDto } from "../data/models/role";
import {
    InvitationFormValues,
    Totp,
    UserDto,
    User,
    UserCreationDto,
    UserFormValues,
    UserDetailsDto,
    UserInstitutionDto,
    TenantUserDto,
    UserForAnotherTenant,
    AssignExistingUserToTenant,
    ResponsiblePersonResponseDto,
    EventAttendee,
} from "../data/models/user";
import { store } from "../stores/store";
import {
    Tenant,
    TenantDto,
    TenantFormValues,
    UserTenant,
} from "../data/models/tenant";
import { LanguageDto } from "../data/models/language";
import {
    Institution,
    InstitutionDetailsDto,
    InstitutionFormValues,
    InstitutionUserDto,
} from "../data/models/institution";
import { Room, RoomFormValues } from "../data/models/room";
import { router } from "../router/Routes";
import {
    CalendarEvent,
    EventDetailsDto,
    EventDeleteDto,
    EventStatusDto,
    EventConfirmDto,
    EventType,
    Helper,
    CalendarRoom,
    EventUpdateDto,
    EventCreateDto,
    EventDisplay,
    PMSessionEventToCreate,
    AssessorEventStatus,
    ConfirmationEventStatusDto,
} from "../data/models/event";
import {
    PasswordUpdateDto,
    ProfileDetailsDto,
    ProfileUpdateDto,
    ResetPasswordDto,
} from "../data/models/profile";
import {
    Client,
    ClientBasicInfoDto,
    ClientUpdateDto,
    Timeline,
    TaskDetails,
    ClientAboutDto,
    TaskCreateDto,
    Task,
    ClientDetailsCreateDto,
    ClientDetailsDto,
    ProcessClient,
    ClientQueryParams,
    ClientDetailSessionsDto,
    ClientSessionTimelineOverviewItem,
    ClientGeneralInfo,
} from "../data/models/client";
import { PaginatedResult, Pagination } from "../data/models/pagination";
import {
    AnswerSubmit,
    Survey,
    SurveyResponse,
    SurveyTranslateField,
    SurveyView,
    TempSession,
} from "../data/models/survey";
import {
    ProcessClientsAddDto,
    ProcessDetails,
    ProcessFormValues,
} from "../data/models/process";
import {
    Assessor,
    ClientSessionInfo,
    FollowUpType,
    SessionAttempt,
    SessionDetailsDto,
    TimelineEvent,
    SessionTimeline,
    SessionFile,
    ClientSession,
    BreakDuration,
    SessionStart,
    SessionEmergencyRequest,
    SessionDeactivateRequest,
} from "../data/models/session";
import { getDecodedToken } from "./jwtDecoder";
import i18n from "../data/translations/i18n";
import { AvailableVariablesResponse, DateRange, K10Stats, NoEventStats, PmStats, ScreeningStats } from "../data/models/stats";
import { HealthInsuranceDto } from "../data/models/healthInsurance";
import { AuditLog, AuditLogQueryParams } from "../data/models/auditLog";
import { TemplateContentDto, TemplateDefinitionDto, TemplateInstanceCreateDto, TemplateInstanceDto, TemplatePatchDto } from "../data/models/notification";
import { NotificationResultLog } from "../data/models/notificationLog";
import { SessionActionDto, StudyOverviewDTO } from "../data/models/studyOverview";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use((config) => {
    const token = store.commonStore.token;
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

const getErrorMessage = (data: any) => {
    let errorMessage;

    if (typeof data === 'string') {
        errorMessage = data;
    } else if (typeof data === 'object' && data.message) {
        errorMessage = data.message;
    } else {
        errorMessage = 'Unknown error occurred';
    }

    return errorMessage;
}

axios.interceptors.response.use(
    async (response) => {
        const pagination = response.headers["x-pagination"];
        if (pagination) {
            response.data = new PaginatedResult(
                response.data,
                JSON.parse(pagination)
            );
            return response as AxiosResponse<PaginatedResult<any>>;
        }
        return response;
    },
    (error: AxiosError) => {
        const t = i18n.getFixedT(i18n.language, null);
        if (error.response) {
            const { data, status, config } = error.response as AxiosResponse;
            const errorKey = t(`REQ_ERROR_${getErrorMessage(data)}`).replace("REQ_ERROR_", "");
            switch (status) {
                case 400:
                    if (config.method === "get" && data.errors?.hasOwnProperty("id")) {
                        router.navigate("/not-found");
                    }
                    if (data.errors && data.errors["$.clientId"]) {
                        const errorMessage = t("REQ_ERROR_CLIENT_NOT_EXIST");
                        toast.error(errorMessage);
                        return Promise.reject(new Error(errorMessage));
                    }
                    if (data.errors) {
                        const modalStateErrors = [];
                        for (const key in data.errors) {
                            if (data.errors[key]) {
                                modalStateErrors.push(data.errors[key]);
                            }
                        }
                        toast.error(t("REQ_ERROR_BAD_REQUEST"));
                        throw modalStateErrors.flat();
                    } else {
                        // TODO: Remove this ASAP
                        const sessionError =
                            error.request.responseURL.includes("/api/Sessions") &&
                            data === "INVALID_TOKEN";
                        if (!sessionError) {
                            toast.error(errorKey);
                        }
                    }
                    break;
                case 401:
                    const jwt = sessionStorage.getItem("jwt");
                    if (jwt) {
                        const decodedToken = getDecodedToken(jwt);
                        const currentTime = Math.floor(Date.now() / 1000);

                        if (decodedToken.exp - currentTime < 0) {
                            sessionStorage.clear();
                            router.navigate("/login");
                            return;
                        }
                    }
                    toast.error(t("REQ_ERROR_UNAUTHORIZED"));
                    break;
                case 403:
                    if (typeof data === "object" && data.redirect === true) {
                        toast.error(t(`REQ_ERROR_${data.message}`).replace("REQ_ERROR_", ""));
                        router.navigate("/not-found");
                        break;
                    }

                    toast.error(errorKey || t("REQ_ERROR_FORBIDDEN"));
                    break;
                case 404:
                    toast.error(errorKey || t("REQ_ERROR_NOT_FOUND"));
                    break;
                case 500:
                    toast.error(t("REQ_ERROR_SERVER"));
                    console.log(error);
                    break;
            }
        } else {
            if (error.config?.url?.includes('/Sessions/survey/submit-answer')) {
                toast.error(t("REQ_ERROR_SURVEY_ANSWER"));
            } else {
                toast.error(`${t("REQ_ERROR_NETWORK")} ${t("REQ_ERROR_RELOAD")}`);
            }
        }
        return Promise.reject(error);
    }
);

const requests = {
    get: <T>(url: string, body?: {}) =>
        axios.get<T>(url, body).then(responseBody),
    post: <T>(url: string, body: {}) =>
        axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    patch: <T>(url: string, body: {}) => axios.patch<T>(url, body).then(responseBody),
    del: <T>(url: string, body?: {}) =>
        axios.delete<T>(url, body).then(responseBody),
    postForm: <T>(
        url: string,
        body: object | null = null,
        filesName: string,
        files: File[]
    ) => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append(filesName, file);
        });
        if (body) {
            Object.entries(body).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }

        return axios
            .post<T>(url, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(responseBody);
    },
    putForm: <T>(
        url: string,
        body: object | null = null,
        filesName: string,
        files: File[]
    ) => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append(filesName, file);
        });
        if (body) {
            Object.entries(body).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach((element) => {
                        formData.append(key, element);
                    });
                } else {
                    formData.append(key, value);
                }
            });
        }

        return axios
            .put<T>(url, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(responseBody);
    },
};

const Auth = {
    current: () => requests.get<User>("/Auth"),
    login: (user: UserFormValues) => requests.post<User>("/Auth/login", user),
    register: (user: UserCreationDto) =>
        requests.post<User>("/Auth/register", user),
    adminVerify: (twoFactorRequest: Totp, token?: string | null) =>
        requests.post<User>(
            `/Auth/enable-two-way-auth?token=${token}`,
            twoFactorRequest
        ),
    qrCodeAuthorize: (twoFactorRequest: Totp, token?: string | null) =>
        requests.post<User>(`/Auth/validate-totp?token=${token}`, twoFactorRequest),
    generatePsk: () => requests.post<{ token: string }>("/Auth/generate-psk", {}),
    invite: (user: InvitationFormValues) =>
        requests.post<string>("/Auth/invite-user", user),
    disableTwoFactor: (totp: string) =>
        requests.post("/Auth/disable-two-way-auth", { totp: totp }),
    refreshSession: () => requests.post<User>("/Auth/extend-session", {}),
};

const Roles = {
    get: (tenantId: string) =>
        requests.get<RoleDto[]>(`/Roles/by-tenant/${tenantId}`),
    getById: (roleId: number) => requests.get<Role>(`/Roles/${roleId}`),
    create: (role: Role) => requests.post("/Roles", role),
    update: (role: Role) => requests.put(`/Roles/${role.id}`, role),
    delete: (roleId: number) => requests.del(`/Roles/${roleId}`),
};

const Tenants = {
    get: () => requests.get<Tenant[]>("/Tenants"),
    getById: (tenantId: string) =>
        requests.get<TenantDto>(`/Tenants/${tenantId}`),
    create: (tenant: TenantFormValues) => requests.post("/Tenants", tenant),
    update: (tenant: TenantFormValues) =>
        requests.put(`/Tenants/${tenant.id}`, tenant),
    delete: (tenantId: string) => requests.del(`/Tenants/${tenantId}`),
};

const Rooms = {
    get: (tenantId: string) =>
        requests.get<Room[]>(`/Rooms/tenant-rooms/${tenantId}`),
    getByInstitutionId: (institutionId: string) =>
        requests.get<Room[]>(`/Rooms/institution-rooms/${institutionId}`),
    getById: (roomId: string) => requests.get<Room>(`/Rooms/${roomId}`),
    create: (room: RoomFormValues) => requests.post("/Rooms", room),
    update: (room: RoomFormValues) => requests.put(`/Rooms/${room.id}`, room),
    delete: (roomId: string) => requests.del(`/Rooms/${roomId}`),
    activate: (roomId: string) => requests.put(`/Rooms/activate-room/${roomId}`, {})
};

const Institutions = {
    getByTenant: (tenantId: string) =>
        requests.get<Institution[]>(`/Institutions/by-tenant/${tenantId}`),
    getUsersByInstitution: (institutionId: string) =>
        requests.get<InstitutionUserDto[]>(
            `/Institutions/by-institution/${institutionId}/users`
        ),
    getById: (institutionId: string) =>
        requests.get<InstitutionDetailsDto>(`/Institutions/${institutionId}`),
    getUsers: (tenantId: string) =>
        requests.get<TenantUserDto[]>(`/Institutions/by-tenant/${tenantId}/users`),
    getAssignedUsers: (institutionId: string) =>
        requests.get<InstitutionUserDto[]>(
            `/Institutions/${institutionId}/assigned-users`
        ),
    create: (institution: InstitutionFormValues) =>
        requests.post("/Institutions", institution),
    update: (institution: InstitutionFormValues) =>
        requests.put(`/Institutions/${institution.id}`, institution),
    delete: (institutionId: string) =>
        requests.del(`/Institutions/${institutionId}`),
    getUserPermissions: (institutionId: string) =>
        requests.get<PermissionDto>(`/Institutions/${institutionId}/permissions`),
    getResponsiblePeople: (institutionId: string) =>
        requests.get<ResponsiblePersonResponseDto[]>(`/Institutions/${institutionId}/responsible-people`),
};

const Users = {
    get: (tenantId: string) =>
        requests.get<UserDto[]>(`/Users/by-tenant/${tenantId}`),
    getById: (userId: string, tenantId: string, institutionId: string) =>
        requests.get<UserDetailsDto>(
            `/Users/${userId}/by-tenant/${tenantId}/institution/${institutionId}`
        ),
    checkUser: (institutionId: string, email: string) =>
        requests.get<UserForAnotherTenant>(
            `/Users/check-existence/institutions/${institutionId}?email=${email}`
        ),
    getAssignedPermissions: (userId: string, tenantId: string) =>
        requests.get<UserInstitutionDto[]>(
            `/Users/${userId}/roles/by-tenant/${tenantId}`
        ),
    create: (user: UserCreationDto) => requests.post("/Users", user),
    assignUserToTenant: (user: AssignExistingUserToTenant) =>
        requests.post("/Users/assign-existing-user", user),
    update: (user: UserCreationDto, institutionId: string) =>
        requests.put(`/Users/${user.id}/institutions/${institutionId}`, user),
    delete: (userId: string, institutionId: string) =>
        requests.del(`/Users/${userId}/institutions/${institutionId}`),
    reset2fa: (userId: string, institutionId: string) =>
        requests.put(`/Users/${userId}/institutions/${institutionId}/reset-2fa`, {}),
    deactivate: (userId: string, institutionId: string) =>
        requests.put(`/Users/${userId}/institution/${institutionId}/deactivate`, {}),
    reactivate: (userId: string, institutionId: string) =>
        requests.put(`/Users/${userId}/institution/${institutionId}/reactivate`, {}),
    anonymize: (userId: string, institutionId: string) =>
        requests.put(`/Users/${userId}/institution/${institutionId}/anonymize`, {})
};

const Profile = {
    get: () => requests.get<ProfileDetailsDto>(`/Account`),
    update: (profile: ProfileUpdateDto) => requests.put(`/Account`, profile),
    updatePassword: (password: PasswordUpdateDto) =>
        requests.post(`/Account/change-password`, password),
    getTenants: () => requests.get<UserTenant[]>(`/Account/userTenants`),
    sendPasswordResetEmail: (email: string) =>
        requests.post(`/Account/forgot-password`, { email }),
    sendResetPassword: (resetPassword: ResetPasswordDto) =>
        requests.post(`/Account/reset-password`, resetPassword),
    changeLang: (language: string) =>
        requests.put(`/Account/change-portal-language`, { language }),
};

const Events = {
    get: (userId: string, institutionId: string) =>
        requests.get<EventDisplay>(`/Events/by-user/${userId}/${institutionId}`),
    getClientEvents: (userId: string, institutionId: string) =>
        requests.get<CalendarEvent[]>(
            `/Events/by-client/${userId}/${institutionId}`
        ),
    getById: (eventId: string) =>
        requests.get<EventDetailsDto>(`/Events/${eventId}`),
    getUsersCalendar: (institutionId: string, languages?: string[]) => {
        const params = new URLSearchParams();
        if (languages && languages.length > 0) {
            languages.forEach((language) => {
                params.append("languages", language);
            });
        }
        const queryString = params.toString();
        return requests.get<TenantUserDto[]>(
            `/Events/users/${institutionId}${queryString ? `?${queryString}` : ""}`
        );
    },
    getRooms: (institutionId: string) =>
        requests.get<CalendarRoom[]>(`/Events/rooms/${institutionId}`),
    getRoomEvents: (roomId: string, institutionId: string) =>
        requests.get<CalendarEvent[]>(`/Events/by-room/${roomId}/${institutionId}`),
    getEventTypes: (tenantId: string) =>
        requests.get<EventType[]>("/Events/event-types", tenantId),
    getHelpers: (institutionId: string) =>
        requests.get<Helper[]>(`/Events/list-helpers/${institutionId}`),
    getCurrentUserEvents: (institutionId: string) =>
        requests.get<CalendarEvent[]>(`/Events/my-events/${institutionId}`),
    create: (calendarEvent: EventCreateDto) =>
        requests.post("/Events", calendarEvent),
    createClientEvent: (calendarEvent: EventCreateDto) =>
        requests.post("/Events/client-event", calendarEvent),
    update: (calendarEvent: EventUpdateDto, institutionId: string) =>
        requests.put(`/Events/${calendarEvent.id}/${institutionId}`, calendarEvent),
    updateExceptionEvent: (
        eventForUpdateDto: EventUpdateDto,
        exceptionDate: string
    ) =>
        requests.post(
            `/Events/${eventForUpdateDto.id}/exception/update?exceptionDate=${exceptionDate}`,
            eventForUpdateDto
        ),
    changeEventStatus: (evenStatusDto: EventStatusDto) =>
        requests.post("/Events/event-status", evenStatusDto),
    deleteEvent: (institutionId: string, eventForDeleteDto: EventDeleteDto) =>
        requests.post(`/Events/${institutionId}`, eventForDeleteDto),
    deleteClientEvent: (
        institutionId: string,
        eventForDeleteDto: EventDeleteDto
    ) =>
        requests.post(
            `/Events/delete-client-event/${institutionId}`,
            eventForDeleteDto
        ),
    addEventException: (eventId: string, exceptionDate: string | null) =>
        requests.post(
            `/Events/${eventId}/exception/delete?exceptionDate=${exceptionDate}`,
            {}
        ),
    noShow: (eventId: string) => requests.put(`/Events/client-no-show/${eventId}`, {}),
    // cofirmSchedule: (eventId: string, confirmEventDto: EventConfirmDto) =>
    //     requests.put(`/Events/${eventId}/confirm`, confirmEventDto),
    cofirmSchedule: (eventId: string, institutionId: string) =>
        requests.post(`/Events/${eventId}/confirm-schedule/${institutionId}`, {}),
    getEventAttendees: (institutionId: string) => {
        return requests.get<EventAttendee[]>(`/Events/event-attendees/${institutionId}`);
    },
    getPMSessionEventsToCreate: (attendees: string, startDate: string, endDate: string, institutionId: string, subType: string, timezone: string) => {
        return requests.get<PMSessionEventToCreate[]>(`/Events/get-pm-events-to-create?${attendees}&startDate=${startDate}&endDate=${endDate}&institutionId=${institutionId}&subType=${subType}&timezoneId=${timezone}`)
    },
    createPMSessionEvents: (pmSessionEventsToCreate: EventCreateDto[]) =>
        requests.post("/Events/create-pm-session-events", pmSessionEventsToCreate)
    ,
    changeConfirmationEventStatus: (confirmationEvent: ConfirmationEventStatusDto) => {
        const url = `/Events/${confirmationEvent.eventId}/confirm?token=${encodeURIComponent(confirmationEvent.token)}&userId=${encodeURIComponent(confirmationEvent.userId)}`;
        return requests.put<AxiosResponse>(url, { status: confirmationEvent.status });
    },
}

const Language = {
    get: () => requests.get<LanguageDto[]>("/Language"),
};

const MasterData = {
    getHealthInsuranceOptions: () => requests.get<HealthInsuranceDto[]>("/MasterData/health-insurance-values"),
};

const Clients = {
    get: (institutionId: string, queryParams: ClientQueryParams) =>
        requests.get<PaginatedResult<Client[]>>(
            `/Clients/${institutionId}?` +
            (queryParams.roleId !== undefined
                ? `roleId=${queryParams.roleId}`
                : "") +
            (queryParams.status !== undefined
                ? `&status=${queryParams.status}`
                : "") +
            (queryParams.paging !== undefined
                ? `&page=${queryParams.paging?.page}&pageSize=${queryParams.paging?.pageSize}`
                : "") +
            (queryParams.search ? `&search=${queryParams.search}` : "") +
            (queryParams.sortField ? `&sortField=${queryParams.sortField}` : "") +
            (queryParams.sortOrder ? `&sortOrder=${queryParams.sortOrder}` : "")
        ),
    getGeneralInfo: (clientId: string) =>
        requests.get<ClientGeneralInfo>(`Clients/${clientId}/general-info`),

    getClientInfo: (clientId: string, institutionId: string) =>
        requests.get<ClientAboutDto>(`/Clients/${clientId}/about?institutionId=${institutionId}`),
    getClientDetails: (clientId: string, institutionId: string) =>
        requests.get<ClientDetailsDto>(`/Clients/${clientId}/details?institutionId=${institutionId}`),
    getById: (clientId: string) => requests.get<Client>("/Clients", clientId),
    create: (clientForCreation: ClientBasicInfoDto) =>
        requests.post<{ id: string }>("/Clients/basic-info", clientForCreation),
    createDetails: (clientDetails: ClientDetailsCreateDto) =>
        requests.post("/Clients/details", clientDetails),
    update: (clientId: string, clientForUpdate: ClientUpdateDto) =>
        requests.put(`/Clients/${clientId}/basic-info`, clientForUpdate),
    updateDetails: (clientId: string, clientDetails: ClientDetailsCreateDto) =>
        requests.put(`/Clients/${clientId}/details`, clientDetails),
    delete: (clientId: string, institutionId: string) =>
        requests.del(`/Clients/${clientId}/institutions/${institutionId}`),
    getTimeline: (userId: string, pagingParams?: Pagination) =>
        requests.get<PaginatedResult<Timeline[]>>(
            `/Clients/${userId}/timeline` +
            (pagingParams !== undefined
                ? `?page=${pagingParams?.page}&pageSize=${pagingParams?.pageSize}`
                : "")
        ),
    getRoles: (tenantId: string) =>
        requests.get<RoleDto[]>(`/Clients/${tenantId}/roles`),
    getTasks: (clientId: string) =>
        requests.get<PaginatedResult<TaskDetails[]>>(`/Clients/${clientId}/tasks`),
    getTask: (taskId: string) =>
        requests.get<TaskDetails>(`/Clients/tasks/${taskId}`),
    createTask: (task: TaskCreateDto, files: File[]) =>
        requests.postForm("/Clients/create-task", task, "files", files),
    updateTaskStatus: (taskId: string) =>
        requests.put(`/Clients/tasks/${taskId}/update-completion-status`, {}),
    updateTask: (taskId: string, task: Task, files: File[]) =>
        requests.putForm(
            `/Clients/tasks/${taskId}/update`,
            task,
            "newFiles",
            files
        ),
    deleteTask: (taskId: string) => requests.del(`/Clients/tasks/${taskId}`),
    assessorList: (institutionId: string) =>
        requests.get<Assessor[]>(`/Clients/eligible-assessors/${institutionId}`),
    surveyResponse: (
        responseId: string,
        institutionId: string,
        language: string = "en"
    ) =>
        requests.get<Survey>(
            `/Clients/responses/${responseId}/institution/${institutionId}?languageId=${language}`
        ),
    setAhvNumber: (clientId: string, ahvNumber: string) =>
        requests.put(`/Clients/update-ahv-number/${clientId}`, { ahvNumber }),

    getClientDetailSessions: (clientId: string, institutionId: string) =>
        requests.get<ClientDetailSessionsDto[]>(`/Clients/${clientId}/institutions/${institutionId}/sessions`),

    getClientSessionsTimelineOverview: (clientId: string, institutionId: string, sessionId: string) =>
        requests.get<ClientSessionTimelineOverviewItem[]>(`/clients/${clientId}/institutions/${institutionId}/sessions/${sessionId}/timeline-overview`),

    updateFields: (clientId: string, data: any) => axios.patch(`/Clients/${clientId}/details`, data, {
        headers: {
            "Content-Type": "application/json-patch+json"
        }
    }).then(responseBody),

};

const Questionnaire = {
    list: () => requests.get<SurveyView[]>(`/Test`),
    get: (surveyId: string, language: string = "en") =>
        requests.get<Survey>(`/Test/${surveyId}?languageId=${language}`),
    timelineSurvey: (
        timelineId: string,
        surveyId: string,
        sessionId: string,
        token?: string,
        language: string = "en"
    ) =>
        requests.get<Survey>(
            `/Sessions/${sessionId}/${timelineId}/${surveyId}?languageId=${language}${token ? "&token=" + token : ""
            }`
        ),
    submitAnswer: (answer: AnswerSubmit, token?: string) =>
        requests.put(
            `/Sessions/survey/submit-answer${token ? "?token=" + token : ""}`,
            answer
        ),
    previewSurvey: (
        timelineId: string,
        surveyId: string,
        languageId: string = "en"
    ) =>
        requests.get<Survey>(
            `/Surveys/timeline/${timelineId}/survey/${surveyId}?languageId=${languageId}`
        ),
    translate: (surveyId: string, translations: SurveyTranslateField[]) => requests.put(`/Surveys/${surveyId}/translate`, { updateRequests: translations }),
}

const Process = {
    list: (institutionId: string) => requests.get<ProcessDetails[]>(`/Processes/institutions/${institutionId}`),
    details: (id: string) => requests.get<ProcessDetails>(`/Processes/${id}`),
    addProcess: (processCreationDto: ProcessFormValues) =>
        requests.post<ProcessFormValues>(`/Test/Processes`, processCreationDto),
    addClientsToProcess: (processClientAdd: ProcessClientsAddDto) =>
        requests.post(`/Processes/add-clients`, processClientAdd),
    clientList: (institutionId: string) =>
        requests.get<ProcessClient[]>(
            `/Processes/institutions/${institutionId}/eligible-assignment-clients`
        ),
};

const Session = {
    list: (institutionId: string) =>
        requests.get<TempSession[]>(`/Sessions/by-institution/${institutionId}`),
    getSession: (sessionId: string, sessionToken?: string) => {
        let url = `/Sessions/${sessionId}/details`;
        if (sessionToken) {
            url += `?token=${sessionToken}`;
        }

        return requests.get<SessionDetailsDto>(url);
    },
    assessorSession: (sessionId: string) => requests.get<SessionDetailsDto>(`/Sessions/${sessionId}/overview`),
    startSession: (sessionId: string) =>
        requests.put(`/Sessions/${sessionId}/start`, {}),
    submit: (survey: SurveyResponse, token?: string) => {
        const queryParam = token ? `?token=${token}` : "";
        return requests.post(`/Sessions/survey/submit${queryParam}`, survey);
    },
    consentAgreement: (sessionId: string, accepted: boolean) =>
        requests.put(`/Sessions/${sessionId}/consent-agreement`, { accepted }),
    surveyList: (sessionId: string) =>
        requests.get<SessionTimeline[]>(`/Sessions/${sessionId}/surveys-status`),
    crisisAssessment: (sessionId: string, accepted: boolean) =>
        requests.put(`/Sessions/${sessionId}/suicide-conclusion`, { accepted }),
    excludeClient: (sessionId: string, exclude: boolean) =>
        requests.put(`/Sessions/${sessionId}/exclusion`, { isExclusionCriteriaMet: exclude }),
    sessionsByClient: (clientId: string) =>
        requests.get<ClientSessionInfo[]>(`/Sessions/by-client/${clientId}`),
    verify: (sessionId: string, code: string) =>
        requests.post<{ token: string }>(`/Sessions/${sessionId}/verify-code`, {
            code,
        }),
    resetCode: (sessionId: string) =>
        requests.post(`/Sessions/${sessionId}/reset-session-code`, {}),
    attemptDetails: (sessionId: string) =>
        requests.get<SessionAttempt>(`/Sessions/${sessionId}/number-of-attempts`),
    noShow: (sessionId: string) =>
        requests.put(`/Sessions/${sessionId}/no-show-up`, {}),
    followUpType: (sessionId: string, type: FollowUpType) =>
        requests.put(`/Sessions/${sessionId}/follow-up-type`, { type }),
    events: (sessionId: string, timelineId: string) =>
        requests.get<TimelineEvent[]>(
            `/Sessions/${sessionId}/timeline/${timelineId}/event-status`
        ),
    fileUpload: (sessionId: string, order: number, file: File) =>
        requests.postForm(
            `/Sessions/${sessionId}/upload-files`,
            { order },
            "file",
            [file]
        ),
    fileList: (sessionId: string) =>
        requests.get<SessionFile[]>(`/Sessions/${sessionId}/consent-files`),
    updateFile: (sessionId: string, fileId: string, file: File) =>
        requests.putForm(
            `/Sessions/${sessionId}/update-consent-files/${fileId}`,
            {},
            "file",
            [file]
        ),
    clientSessions: () =>
        requests.get<ClientSession[]>("/Sessions/client-sessions"),
    confirmBaseline: (sessionId: string) => requests.put(`/Sessions/${sessionId}/confirm-baseline-results`, {}),
    hasStarted: (eventId: string) => requests.get<SessionStart>(`/Sessions/timeline-started/${eventId}`),
    sessionEmergency: (sessionId: string, request: SessionEmergencyRequest) =>
        requests.put(`/Sessions/${sessionId}/emergency`, request),
    sessionDeactivate: (sessionId: string, request: SessionDeactivateRequest) =>
        requests.put(`/Sessions/${sessionId}/deactivate`, request),
    resendEmail: (sessionId: string) =>
        requests.post(`/Sessions/${sessionId}/resend-email`, {}),
    getSessionCode: (sessionId: string) => requests.get<{ code: string }>(`/Sessions/${sessionId}/code`)
};

const SessionOperations = {
    triageConsent: (sessionId: string, accepted: boolean) =>
        requests.put(`/Sessions/${sessionId}/operations/triage-consent`, { accepted }),
    atsPostponeToEndOfBaseline: (sessionId: string) =>
        requests.put(`/Sessions/${sessionId}/operations/ats-postpone`, {}),
    atsPostponeToIntervention: (sessionId: string) =>
        requests.put(`/Sessions/${sessionId}/operations/ats-postpone-intervention`, {}),
}

const SessionBreak = {
    get: (sessionId: string, breakId: string) => requests.get<BreakDuration>(`/SessionBreaks/${sessionId}/client-break/${breakId}`),
    start: (sessionId: string, breakId: string, token?: string) => requests.post<any>(`/SessionBreaks/${sessionId}/start-break/${breakId}${token ? "?token=" + token : ""}`, {}),
    end: (sessionId: string, breakId: string, token?: string) => requests.post<any>(`/SessionBreaks/${sessionId}/end-break/${breakId}${token ? "?token=" + token : ""}`, {}),
}

const DataExport = {
    requestExport: (payload: any) => {
        return requests.post<string>(
            `/DataExport/export-data`, payload
        )
    },
    bridgeTable: (institutionId: string) => requests.get<string>(`/DataExport/${institutionId}/bridge-data`),
    exportConsentFilesData: (institutionId: string) => requests.get<Blob>(`/DataExport/${institutionId}/consent-files`, {
        responseType: 'arraybuffer'
    }),
    sessionStats: (institutionId: string, range?: DateRange) => {
        const queryParam = range ? `?startDate=${range.startDate}&endDate=${range.endDate}` : "";
        return requests.get<ScreeningStats>(`/DataExport/institution/${institutionId}/session-stats${queryParam}`)
    },
    interventionStats: (institutionId: string, range?: DateRange) => {
        const queryParam = range ? `?startDate=${range.startDate}&endDate=${range.endDate}` : "";
        return requests.get<ScreeningStats>(`/DataExport/institution/${institutionId}/intervention-stats${queryParam}`)
    },
    noEventStats: (institutionId: string, range?: DateRange) => {
        const queryParam = range ? `?startDate=${range.startDate}&endDate=${range.endDate}` : "";
        return requests.get<NoEventStats[]>(`/DataExport/institution/${institutionId}/no-event-stats${queryParam}`)
    },
    pmStats: (institutionId: string, range?: DateRange) => {
        const queryParam = range ? `?startDate=${range.startDate}&endDate=${range.endDate}` : "";
        return requests.get<PmStats[]>(`/DataExport/institution/${institutionId}/completed-pm-events${queryParam}`)
    },
    k10Stats: (institutionId: string, timeline: number, range?: DateRange) => {
        const queryParam = range ? `?timelineNumber=${timeline}&startDate=${range.startDate}&endDate=${range.endDate}` : "";
        return requests.get<K10Stats>(`/DataExport/institution/${institutionId}/k10-stats${queryParam}`)
    },
    availableVariables: () => requests.get<AvailableVariablesResponse>(`/DataExport/availableVariables`),
};

const AuditLogs = {
    get: (queryParams: AuditLogQueryParams, dateRange?: DateRange) => {
        const queryParam = dateRange ? `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}` : "";

        return requests.get<PaginatedResult<AuditLog[]>>(`/AuditTrails?page=${queryParams.paging?.page}&pageSize=${queryParams.paging?.pageSize}${queryParam}`)
    }

};

const NotificationLogs = {
    get: (queryParams: { paging: Pagination }, selectedInstitution: string | null, dateRange?: DateRange) => {
        const queryParam = dateRange ? `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}` : "";
        return requests.get<PaginatedResult<NotificationResultLog[]>>(`/NotificationLogs/${selectedInstitution}?page=${queryParams.paging?.page}&pageSize=${queryParams.paging?.pageSize}${queryParam}`);
    }
};


/*Includes api requests related to notifications */
const Notifications = {
    /**
     * 
     * @returns 
     */
    get: () => {
        return requests.get<TemplateDefinitionDto[]>(`/NotificationTemplates/definitions`)
    },
    /**
     * 
     * @param templateDefinitionId 
     * @param institutionid 
     * @returns 
     */
    getInstances: (templateDefinitionId: string, institutionid: string, languageId: string = 'en') => {
        return requests.get<TemplateInstanceDto[]>(`/NotificationTemplates/${templateDefinitionId}/institutions/${institutionid}/instances?lang=${languageId}`)
    },
    /**
     * 
     * @param defitinitionId 
     * @returns 
     */
    getContent: (templateDefinitionId: string) => {
        return requests.get<TemplateContentDto[]>(`/NotificationTemplates/instances/${templateDefinitionId}/contents`)
    },
    /**
     * 
     * @param definitionId 
     * @param templateInstanceForCreate 
     * @returns 
     */
    createInstance: (templateDefinitionId: string, templateInstanceForCreate: TemplateInstanceCreateDto) => {
        return requests.post<TemplateInstanceCreateDto>(`/NotificationTemplates/definitions/${templateDefinitionId}/create-instance`, templateInstanceForCreate)
    },
    /**
     * 
     * @param instanceId 
     * @returns 
     */
    updateContent: (templateInstanceId: string, templateInstanceForUpdate: TemplateInstanceCreateDto) => {
        return requests.put<TemplateInstanceDto>(`/NotificationTemplates/${templateInstanceId}`, templateInstanceForUpdate)
    },
    /**
     * 
     * @param instanceId 
     * @returns 
     */
    patchInstance: (templateInstanceId: string, institutionId: string, patchDocument: TemplatePatchDto) => {
        return requests.patch<TemplateInstanceDto>(`/NotificationTemplates/instances/${templateInstanceId}/${institutionId}`, patchDocument)
    },
    /**
     * 
     * @param instanceId 
     * @returns 
     */
    deleteInstance: (templateInstanceId: string) => {
        return requests.del<TemplateInstanceDto>(`/NotificationTemplates/instances/${templateInstanceId}`, {})
    },
    previewWhatsappNotification: (phoneNumber: string, message: string) => {
        return requests.post(`/NotificationTemplates/preview-whatsapp-notification`, { phoneNumber, message })
    },
}

const StudyOverview = {
    getStudyOverview: (institutionId: string, { page, pageSize, searchTerm, groupFilter }: { page: number; pageSize: number; searchTerm?: string, groupFilter?: string[] }) => {
        return requests.get<PaginatedResult<StudyOverviewDTO[]>>(`/StudyOverview/${institutionId}`, {
            params: { page, pageSize, searchTerm, groupFilter: groupFilter ? groupFilter.join(',') : undefined, },
        });
    },
    getClientSessionHistoryActions: (clientId: string) => {
        return requests.get<string[]>(`/StudyOverview/sessionHistory/${clientId}`)
    }
};

const agent = {
    Auth,
    Roles,
    Tenants,
    Rooms,
    Institutions,
    Users,
    Profile,
    Events,
    Language,
    Clients,
    Questionnaire,
    Process,
    Session,
    SessionBreak,
    DataExport,
    MasterData,
    AuditLogs,
    Notifications,
    SessionOperations,
    NotificationLogs,
    StudyOverview
};

export default agent;
