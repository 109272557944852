import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Container, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { AssessorEventStatus, ConfirmationEventStatusDto } from "../../../data/models/event";
import { useStore } from "../../../stores/store";
import LoadingComponent from '../../../components/LoadingComponent';

function EventStatus() {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const userId = params.get('userId');
    const eventId = params.get('eventId');
    const status = params.get('status');
    const { bookingStore } = useStore();
    const { loading } = bookingStore;
    const [response, setResponse] = useState<string>();
    useEffect(() => {
        if ((!token && !status && !eventId && !userId) || (status !== AssessorEventStatus.DECLINED && status !== AssessorEventStatus.CONFIRMED)) {
            navigate('/not-found');
        }

        if (!userId || !eventId || !status || !token) {
            navigate('/not-found')
            return
        };
        const confirmationEventStatus: ConfirmationEventStatusDto = {
            token: token,
            userId: userId,
            eventId: eventId,
            status: status
        }
        const confirmEvent = async () => {
            const response = await bookingStore.changeConfirmationEventStatus(confirmationEventStatus);
            setResponse(response);
        }

        confirmEvent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingStore])

    return (
        <Container
            component="main"
            maxWidth="lg"
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            {loading ? <LoadingComponent /> :
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        padding: 3,
                        boxShadow: 3,
                        borderRadius: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: 3,
                        maxWidth: 600,
                        animation: "fadeIn 1s",
                        "@keyframes fadeIn": {
                            from: { opacity: 0 },
                            to: { opacity: 1 },
                        },
                    }}
                >

                    <>
                        {response ? <Typography variant="body1">{t(`REQ_ERROR_${response}`)}</Typography> :
                            <> <CheckCircleOutlineIcon
                                color="success"
                                sx={{ fontSize: 60 }}
                            />
                                <Typography
                                    sx={{ color: "#333", fontSize: 20 }}
                                >
                                    <Trans i18nKey={"EVENT_STATUS"} values={{ status: status }} />
                                </Typography>
                            </>
                        }
                    </>
                </Box>
            }
        </Container >
    );

}

export default observer(EventStatus);