import { Box, Chip, lighten, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { RefObject, SyntheticEvent } from "react";
import { TableHeader } from "../../../../_styles/table/TableHeader";
import { TableText } from "../../../../_styles/table/TableText";
import DropdownMenu from "../../../../components/DropdownMenu";
import { StatusChip } from "../../../../components/form/StatusChip";
import { SessionAction, SessionStatus } from "../../../../data/models/session";
import { StudyOverviewDTO } from "../../../../data/models/studyOverview";
import i18n from "../../../../data/translations/i18n";
import { router } from "../../../../router/Routes";
import { getDateAndTimeFormatByPlatformLanguage, getDateFormatByPlatformLanguage } from "../../../../utils/utils";
import { getDueDateStatusProps } from "../../../Screening/Process/Session/dueDateStatusProps";
import { getEventStatusProps } from "../../../Screening/Process/Session/eventStatusProps";
import { getSessionStatusChip } from "../../../Screening/Process/Session/statusProps";
import { Link } from "react-router-dom";

export const studyOverviewColumns = (t: TFunction,
    handleStatusChange: (sessionId: string, action: SessionAction) => Promise<void>,
    onNoShow: (sessionId: string) => Promise<void>,
    navigate: (path: string) => void,
    popperAnchorRef: RefObject<HTMLButtonElement>,
    handleStatusSelect: (e: SyntheticEvent<HTMLElement>, clientId: string) => void
) => {

    const options = (sessionId: string, sessionStatus: SessionStatus) => {
        const baseOptions = [
            {
                id: 'emergency',
                name: `${t("CLIENT_EMERGENCY")}`,
                onClick: async () => {
                    await handleStatusChange(sessionId, SessionAction.EMERGENCY);
                },
                customStyle: {
                    color: lighten("#dbd600", 0.1),
                    shadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                },
            },
            {
                id: 'resume',
                name: `${t("CLIENT_RESUME")}`,
                onClick: async () => {
                    await handleStatusChange(sessionId, SessionAction.RESUME);
                },
                customStyle: {
                    color: lighten('#2a9d8f', .3),
                    shadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                },
            },
            {
                id: 'deactivate',
                name: `${t("CLIENT_DEACTIVATE")}`,
                onClick: async () => {
                    await handleStatusChange(sessionId, SessionAction.DEACTIVATE);
                },
                customStyle: {
                    color: lighten('#FF0000', .3),
                    shadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                },
            },
            {
                id: 'no-show-up',
                name: `${t("NO_SHOW_UP")}`,
                onClick: async () => {
                    await onNoShow(sessionId);
                },
                customStyle: {
                    color: 'gray',
                },
            }
        ];

        return sessionStatus === SessionStatus.EMERGENCY_HOLD
            ? baseOptions.filter(option => option.id !== 'emergency')
            : baseOptions.filter(option => option.id !== 'resume')

    };

    function navigateToResouceTool(eventId: string): void {
        if (!eventId) return;
        router.navigate(`/resource-planning/view/${sessionStorage.getItem('viewType')}`, { state: { eventId: eventId } })
    }

    return [
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("CLIENT")}</TableHeader>
            ),
            key: "clientName",
            render: (row: StudyOverviewDTO) => (
                <TableText
                    title={row.clientName}
                    onClick={() => navigate(`/users/${row.clientId}/about`)}
                    style={{ cursor: 'pointer', color: 'inherit', textDecoration: "underline" }}
                >
                    {row.clientName}
                </TableText>
            ),
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("CLIENT_RESPONSIBLE_PEOPLE")}</TableHeader>
            ),
            key: "responsiblePeople",
            render: (row: StudyOverviewDTO) => (
                <TableText>{row.responsiblePeople.join(", ")}</TableText>
            ),
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("SESSION_TIMELINE")}</TableHeader>
            ),
            key: "timeline",
            render: (row: StudyOverviewDTO) => (
                <TableText>{row.timeline}</TableText>
            ),
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("CURRENT_EVENT")}</TableHeader>
            ),
            key: "currentEvent",
            render: (row: StudyOverviewDTO) => (
                row.currentEvent && (
                    <TableText
                    // onClick={() => navigateToResouceTool(row.currentEventId)}
                    >
                        <StatusChip
                            sx={{
                                cursor: row.currentEventId && "pointer",
                            }}
                            title={row.currentEvent}
                            status={row.eventStatus}
                            t={t}
                            dueDate={row.dueDate}
                            getStatusProps={getEventStatusProps}
                        />
                    </TableText>
                )
            ),
            className: "process-cell",
        },
        {
            header: <TableHeader>{t("CLIENT_PROCESS_STATUS")}</TableHeader>,
            key: "sessionStatus",
            render: (row: StudyOverviewDTO) => (
                <div>
                    <Box
                        ref={popperAnchorRef}
                        onClick={(e) => handleStatusSelect(e, row.clientId)}
                    >
                        {getSessionStatusChip(row.sessionStatus, t)}
                    </Box>
                </div>
            ),
        },

        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("EVENT_START_DATE")}</TableHeader>
            ),
            key: "date",
            render: (row: StudyOverviewDTO) => (
                row.date && (<TableText>{getDateAndTimeFormatByPlatformLanguage(new Date(row.date), i18n.language)}</TableText>
                )
            ),
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("UPCOMING_EVENT")}</TableHeader>
            ),
            key: "upcomingEvent",
            render: (row: StudyOverviewDTO) => (
                <TableText>
                    {row.upcomingEvent}
                    {' '}
                    {row.upcomingEvent?.includes("PM1") && row.isATSPostponed &&
                        <Chip size='small' label='ATS' sx={{ bgcolor: lighten('#e63946', 0.8), height: "26px", fontSize: '11px', border: `1px solid #e63946` }} />
                    }
                </TableText>
            ),
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("TODO_DATE")}</TableHeader>
            ),
            key: "dueDate",
            render: (row: StudyOverviewDTO) => (
                row.dueDate && (
                    <Typography
                    // onClick={() => navigateToResouceTool(row.upcomingEventId)}
                    >
                        <StatusChip
                            sx={{
                                cursor: row.upcomingEventId && "pointer",
                            }}
                            title={
                                row.upcomingEventStatus === "PROJECTED"
                                    ? getDateFormatByPlatformLanguage(new Date(row.dueDate), i18n.language)
                                    : getDateAndTimeFormatByPlatformLanguage(new Date(row.dueDate), i18n.language)
                            }
                            status={row.upcomingEventStatus}
                            t={t}
                            dueDate={row.dueDate}
                            getStatusProps={getDueDateStatusProps}
                        />
                    </Typography>
                )
            ),
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("GENERAL_GROUP")}</TableHeader>
            ),
            key: "group",
            render: (row: StudyOverviewDTO) => (
                <TableText>{row.group}</TableText>
            ),
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"} width={5}></TableHeader>
            ),
            key: "action",
            render: (row: StudyOverviewDTO) => (
                <TableText>{<DropdownMenu options={options(row.sessionId, row.sessionStatus)} />}</TableText>
            ),
            width: 3,
            className: "process-cell",
        },
    ];


}

